import React from "react";
import sideImage from "../Images/viva_bellissimoMen_2024.jpg";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
function Downloads() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className=" min-h-[calc(100vh-68px)] h-auto bg-[#000000] lg:py-[140px]">
      <div className="container md:mx-0 sm:mx-5 p-4 md:mt-0 md:mb-0  sm:mb-0 lg:flex lg:flex-row lg:items-center md:flex md:flex-row md: justify-between md:items-center mb-28 sm:h-screen ">
        <div className="relative group  lg:flex lg:flex-row lg:items-center md:flex md:flex-col md: justify-center md:items-center sm:hidden">
          <img
            src={sideImage}
            className="md:w-[400px] h-[1000px] lg:w-[500px] object-contain"
          />
          <h1 className="absolute inset-0 mt-48 flex justify-center group-hover:opacity-50 transition-opacity text-white md:text-4xl  lg:text-6xl font-bold">
            Downloads
          </h1>
        </div>
        <div className="lg:ml-60 md:ml-[0px] flex flex-col mt-5 text-white">
          <h1 className="font-bold text-2xl mb-10"> {t("Catalogs")}</h1>
          <a
            href="https://media.bbf-bike.de/shop/documents/viva/viva.pdf"
            target="blank"
          >
            VIVA BIKES 2023
          </a>
          <div className="border-t sm:w-[100px] md:w-[250px] lg:w-[500px]"></div>
          <a
            href="https://media.bbf-bike.de/shop/documents/viva/bedienungsanleitung-viva.pdf"
            target="blank"
            className="mt-10"
          >
            BEDIENUNGSANLEITUNG
          </a>
          <div className="border-t  sm:w-[100px]  md:w-[250px] lg:w-[500px]"></div>
        </div>
      </div>
    </div>
  );
}

export default Downloads;
